import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import {DatePipe} from '@angular/common';

import { registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import { AuthenticatedGuard } from './shared/services/auth/authenticated.guard';
import { TokenService } from './shared/services/auth/token.service';
import { JwtModule, JWT_OPTIONS } from '@auth0/angular-jwt';
import { jwtOptionsFactory } from './shared/services/auth/resources/jwt-options';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import { CustomErrorHandlerService } from './shared/services/custom-error-handler.service';
import { HttpErrorInterceptor } from './shared/interceptors/http-error-interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

registerLocaleData(localeES);

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        IonicModule.forRoot({
            backButtonText: ''
        }),
        AppRoutingModule,
        HttpClientModule,
        ReactiveFormsModule,
        LeafletModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [TokenService]
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        DatePipe,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: LOCALE_ID, useValue: 'es-ES' },
        AuthenticatedGuard,
        TokenService,
        {provide: ErrorHandler, useClass: CustomErrorHandlerService},
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
